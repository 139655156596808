
import SliderTabs from '@/components/SliderTabs.vue'
import { EcosystemId, IEcosystem } from '@/ecosystem'

  import Vue from 'vue'
  import { mapActions } from 'vuex'
  import AwaitLock from 'await-lock'
  import { ethers } from 'ethers'

  import { ERC20_ABI,
           LAUNCHPAD_FACTORY_ABI,
           LAUNCHPAD_PRESALE_ABI,
           APPROVE_AMOUNT } from '@/constants'
  import { ChainId } from '@/ecosystem'
  import { equalsInsensitive, delay } from '@/utils'

  import { PresaleData } from '@/types'


const presales : any = [
]

  export default Vue.extend ({
    components: { SliderTabs },
    data: () => ({
      valid: true,

      updateTable: <boolean> false,
      warningHidden: <boolean> false,

      // Ecosystem-specific
      backgroundImage: '',

      active: true,
      syncLock: new AwaitLock(),
      search: '',

      headers: [
      {
        text: 'Favorite',
        value: 'favorite',
        align: 'center',
        sortable: false
      },
      {
        text: 'Logo',
        value: 'logo',
        align: 'center',
        sortable: false
      },
      {
        text: 'Ticker',
        value: 'ticker',
        align: 'center',
        sortable: false
      },
      {
        text: 'Token name',
        value: 'name',
        align: 'center',
        sortable: false
      },
      {
        text: 'Status',
        value: 'status',
        align: 'center',
        sortable: false
      },
      {
        text: 'Presale link',
        value: 'presaleLink',
        align: 'center',
        sortable: false
      }
    ],
    }),
    created () {
      window.onstorage = () => {
        this.$store.commit('setUserProfile')
      };
      this.updatePresaleList()
    },
    computed: {
      ecosystemId: {
        get(): EcosystemId {
          return this.$store.state.ecosystemId
        },
        set(val: EcosystemId) {
          this.$store.commit('setEcosystemId', val)
        }
      },
      ecosystem(): IEcosystem {
        return this.$store.getters.ecosystem
      },
      address(): string {
        return this.$store.state.address
      },
      web3(): ethers.Signer | null {
        return this.$store.state.web3
      },
      chainId(): ChainId {
        return this.$store.getters.ecosystem.chainId
      },
      multicall(): ethers.providers.Provider {
        return this.$store.getters.multicall
      },
      ecosystemName() : string {
        if (this.$store.getters.ecosystem.chainId == 56) {
          return 'bsc'
        }
        if (this.$store.getters.ecosystem.chainId == 1285) {
          return 'moonriver'
        }
        if (this.$store.getters.ecosystem.chainId == 1284) {
          return 'moonbeam'
        }
        return 'undefined'
      },
      factoryContract(): ethers.Contract {
        return new ethers.Contract(this.$store.getters.ecosystem.launchPadFactoryAddress, LAUNCHPAD_FACTORY_ABI, this.multicall)
      }
    },
    beforeRouteLeave(to, from, next) {
      this.active = false
      next()
    },
    beforeDestroy() {
      this.active = false
    },
    methods: {
      renderTable() {
        this.updateTable = !this.updateTable
      },
      updatePresaleList() {
        presales.length = 0

        let presalesLength = this.factoryContract.allPresalesLength().then(
        (len: ethers.BigNumber) => {
          const length = parseInt(len.toString())
          for (let i = 0; i < length; i += 1)
          this.factoryContract.allPresales(i).then((address: string) => this.getPresaleData(address))
        })
      },
      getPresaleData(presaleAddress : string) {
        const presaleContract = new ethers.Contract(presaleAddress, LAUNCHPAD_PRESALE_ABI, this.multicall)

        let presaleInfo : any = {
          logo: '',
          name: '',
          ticker: '',
          presaleLink: '/' + this.$store.getters.ecosystem.routeName + '/presale/' + presaleAddress,
          status: '',
          isPublic: true
        }
        presales.push(presaleInfo)

        presaleContract.presaleInfo().then((info: string) => {
          const presaleEntry = presales.find((f: any) => equalsInsensitive(f.presaleLink, presaleInfo.presaleLink))
          if (presaleEntry) {
            const parsedInfo = JSON.parse(info)
            presaleEntry.logo = parsedInfo.tokenLogoUrl
            if (parsedInfo.isPublic != undefined) {
              presaleEntry.isPublic = parsedInfo.isPublic
            }
            this.renderTable()
          }
        })

        presaleContract.canBuy().then((canBuy: boolean) => {
          presaleContract.canEnd().then((canEnd: boolean) => {
            presaleContract.isAborted().then((presaleIsAborted: boolean) => {
              const presaleIsActive = canBuy && !canEnd
              const presaleEntry = presales.find((f: any) => equalsInsensitive(f.presaleLink, presaleInfo.presaleLink))
              if (presaleEntry) {
                if (presaleIsActive && !presaleIsAborted) {
                  presaleEntry.status = 'active'
                }
                else if (presaleIsAborted) {
                  presaleEntry.status = 'aborted'
                }
                else {
                  presaleEntry.status = 'finished'
                }
                this.renderTable()
              }
            })
          }) 
        })

        presaleContract.token().then((tokenAddress: string) => this.getTokenData(presaleAddress, tokenAddress, presaleInfo))
      },
      getTokenData(presaleAddress : string, tokenAddress : string, presaleInfo : any) {
        const tokenContract = new ethers.Contract(tokenAddress, ERC20_ABI, this.multicall)

        this.renderTable()

        tokenContract.name().then((tokenName: string) => {
          const presaleEntry = presales.find((f: any) => equalsInsensitive(f.presaleLink, presaleInfo.presaleLink))
          if (presaleEntry) {
            presaleEntry.name = tokenName
            this.renderTable()
          }
        })

        tokenContract.symbol().then((tokenSymbol: string) => {
          const presaleEntry = presales.find((f: any) => equalsInsensitive(f.presaleLink, presaleInfo.presaleLink))
          if (presaleEntry) {
            presaleEntry.ticker = tokenSymbol
            this.renderTable()
          }
        })
      },
      getTableContent() {
        var content = this.getAllPresales()
        var criteria : any = {"active": 3, "finished": 2, "aborted": 1}

        content.sort((a : any, b : any) => {
          if (criteria[a.status] > criteria[b.status]) {
            return -1
          }
          else if (criteria[a.status] < criteria[b.status]) {
            return 1
          }
          else {
            return 0
          }
        })
        return content
      },
      getAllPresales() {
        var presaleList : any = []
        var whiteList : any = presales
        var importedPresales : any = this.$store.state.userProfile.importedPresales[this.ecosystemId]

        whiteList.forEach((item: any) => {
          var presaleListItem = {
            logo: item.logo,
            name: item.name,
            ticker: item.ticker,
            presaleLink: item.presaleLink,
            status: item.status,
            isPublic: item.isPublic
          }
          if (presaleListItem.name && presaleListItem.ticker && presaleListItem.presaleLink && presaleListItem.isPublic) {
              presaleList.push(presaleListItem)
          }
        })
        return presaleList
      },
      presaleImported(presaleLink : string) {
        if (!presaleLink) {
          return
        }

        const importedPresales = this.$store.state.userProfile.importedPresales[this.ecosystemId]

        const existingEntry = importedPresales.find((f: PresaleData) => equalsInsensitive(f.presaleLink, presaleLink))
        if (existingEntry) {
          return true
        }
        return false
      },
      importPresale(importedLogo : string, importedName : string, importedTicker : string, importedLink : string) {
        if (!importedName || !importedTicker || !importedLink) {
          return
        }

        const presaleConfig = {
          logo: importedLogo,
          name: importedName,
          ticker: importedTicker,
          presaleLink: importedLink
        }

        const importedPresales = this.$store.state.userProfile.importedPresales[this.ecosystemId]

        const existingEntry = importedPresales.find((f: PresaleData) => equalsInsensitive(f.presaleLink, presaleConfig.presaleLink))
        if (existingEntry) {
          const id = importedPresales.indexOf(existingEntry)
          if (id > -1) {
            importedPresales.splice(id, 1);
           }
        }
        this.$store.state.userProfile.importedPresales[this.ecosystemId].push(presaleConfig)
      },
      removePresale(presaleLink : string) {
        const importedPresales = this.$store.state.userProfile.importedPresales[this.ecosystemId]

        const existingEntry = importedPresales.find((f: PresaleData) => equalsInsensitive(f.presaleLink, presaleLink))
        if (existingEntry) {
          const id = importedPresales.indexOf(existingEntry)
          if (id > -1) {
            importedPresales.splice(id, 1);
           }
        }
      },
      copyAddress (address : string) {
        let textArea = document.createElement("textarea")
        textArea.value = address
        textArea.style.top = "0"
        textArea.style.left = "0"
        textArea.style.position = "fixed"
        document.body.appendChild(textArea)
        textArea.focus()
        textArea.select()

        let successful = document.execCommand('copy')

        document.body.removeChild(textArea)
      },
      ...mapActions(['requestConnect', 'safeSendTransaction'])
    },
    watch: {
      ecosystemId() {
        this.updatePresaleList()
      }
    }
  })
